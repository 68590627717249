.bg-labaqua {
    background-color: $color-labaqua !important;
}

.bg-labaqua-secondary {
    background-color: #0b235f !important;
}
.logo-contain {
    width: 100%;
    object-fit: cover;
    height: auto;
}

.labaqua-responsive {
    display: none !important;
}

.resized-inputs {
    padding-top: 0px !important;
    .form-control {
        padding: 0.20rem 0.75rem;
        font-size: 13px;
    }
    .col-form-label {
        padding-top: 0.20rem;
        font-size: 13px;
    }
    p {
        font-size: 13px;
    }
    table {
        font-size: 13px;
    }
}



.cursor-pointer {
    cursor: pointer !important;
}


.false-read-only {
    +.true-read-only {
        background-color: #fff !important;
    }
}
  
#page-topbar {
    @media screen and (max-width: 1024px) {
        background-color: $color-labaqua !important;
        .labaqua-responsive {
            display: block !important;
        }
    }
}

.btn-labaqua {
    color: white;
    background-color: $color-labaqua !important;
    border-color: $color-labaqua !important;
    &:hover {
        color: white !important;
        background-color: $color-labaqua !important;
        border-color: $color-labaqua !important;
    }
}

tbody tr {
   cursor: default;
}

.d-hidden {
    visibility: hidden;
}
